@import "../../Global.scss";

.footer {
  background-color: #1f2436;



  .footerwrapper {
    // overflow-y: hidden;
    // overflow-x: hidden;
    padding-top: 50px;
    // width: 100%;
    // display: flex;

    .left {
    //   flex: 0.5;

      h3 {
        font-size: 25px;
        font-weight: 500;
        color: $third-color;

    
      }

      a {
        text-decoration: none  !important;
   
        h4 {
     
          font-size: 18px;
          
          margin-top: 10px;
      font-weight: 300;
          color: $secondary-color;
        
       
        }

        &:hover {
          h4 {
            color: $third-color;
          }
        }
      }
    }

    .right {
  @media screen and (max-width:768px) {
    margin-top: 20px;
  }

      h3 {
        font-size: 25px;
        font-weight: 500;
        color: $third-color;
      }

      .links {
        display: flex;
        margin-top: 10px;

    

        a {
          img {
            padding-right: 25px; 
            height: 30px;

         
          }
        }
      }
    }
  }

  .salutation {
    display: flex;

    text-align: center;
    justify-content: center;
margin-top: 30px;
 

    p {
      color: $secondary-color;
      font-size: 14px;
    }
  }
}
