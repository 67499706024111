@import "../../Global.scss";

.aboutus {
  width: 100%;

  .heading {
    position: relative;
    margin-top: -110px;

    img {
      width: 100%;
      @media screen and (max-width: 768px) {
        height: 500px;
        object-fit: contain;
      }
    }

    h3 {
      margin-top: -100px;
      color: $third-color;
      font-size: 50px;
      font-weight: 500;
      padding-bottom: 120px;
    }
  }

  .section1 {
    img {
      height: 75px;
    }

    p {
      margin-top: 80px;
      text-align: justify;
      color: $secondary-color;
    }

    .text {
      margin-top: 60px;

      .left {
        img {
          width: 100%;
          height: 100%;
        }
      }

      .right {
        h4 {
          color: $third-color;
          font-size: 30px;
          font-weight: 500;
        }

        p {
          margin-top: 20px;
          text-align: justify;
        }
      }
    }
  }

  .section2 {
    background-color: #1f2435;
    @include mobile {
      flex-direction: column-reverse !important;
    }

    h2 {
      padding-top: 50px;
      color: $third-color;
      font-size: 40px;
      font-weight: 500;
      word-spacing: 5px;
    }

    h3 {
      margin-top: 5px;
      color: $secondary-color;
      font-size: 25px;
      font-weight: 400;
    }

    .row1 {
      display: flex;
      overflow-x: hidden;
      overflow-y: hidden;

      .left {
        p {
          font-size: 18px;
          text-align: justify;
          color: $secondary-color;
          width: 85%;
          padding-bottom: 40px;

          @include mobile {
            width: 100%;
            font-size: 15px;
            font-weight: 400;
          }
        }
      }

      .right {
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .row2 {
      display: flex;
      overflow-x: hidden;
      overflow-y: hidden;
      .left {
        img {
          width: 100%;
          height: 100%;
        }
      }

      .right {
        p {
          font-size: 18px;
          text-align: justify;
          color: $secondary-color;

          @include mobile {
            font-size: 15px;
          }
        }
      }
    }

    .row3 {
      .left {
        p {
          font-size: 18px;
          text-align: justify;
          color: $secondary-color;
          width: 85%;
          padding-bottom: 40px;

          @include mobile {
            width: 100%;
            font-size: 15px;
          }
        }
      }

      .right {
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .mission {
    h2 {
      font-size: 40px;
      font-weight: 500;
      word-spacing: 5px;

      @include mobile {
        font-size: 35px;
      }
    }

    p {
      margin-top: 40px;
      color: $secondary-color;
      @include mobile {
        font-size: 14px;
      }
    }

    h5 {
      margin-top: 40px;
      font-size: 20px;
      text-align: center;
      color: $secondary-color;
      font-style: italic;
      font-weight: 500;

      @include mobile {
        font-size: 17px;
      }
    }
  }

  .vision {
    h2 {
      color: $third-color;
      font-size: 40px;
      font-weight: 500;
      word-spacing: 5px;

      @include mobile {
        font-size: 35px;
      }
    }

    p {
      margin-top: 40px;
      color: $secondary-color;
      @include mobile {
        font-size: 15px;
      }
    }

    h5 {
      margin-top: 40px;
      font-size: 30px;
      text-align: center;
      color: $secondary-color;
      font-style: italic;
      font-weight: 300;
    }
  }
}
