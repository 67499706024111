@import "../../Global.scss";

.description-wrapper {
 
  .description-card {
    background-color: #1F2436;
    border-radius: 50px;
    h4{
        font-size: 20px;
        font-weight: 400;
        // margin-top: 10px;
        color: $third-color;
    }
    img {
      width: 100%;
      height: 100px;
    }
  }

  .motto-logo {

    width: 300px;
    
  }

  p {
    // width: 85%;
    color: $secondary-color;
    // margin-left: 120px;

    word-spacing: 3px;
    text-align: justify;

  
  }
}
