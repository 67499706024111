@import "../../Global.scss";



.contact-wrapper {
 
  h4 {
    color: $secondary-color;
    font-size: 20px;
    font-weight: 500;
  }
  img {
    height: 30px;

    @include mobile {
      height: 25px;
    }
  }

  .location {
    display: flex;

    p {
      padding-left: 20px;
      width: 30%;
      color: $secondary-color;
      font-size: 12px;

      @media screen and (max-width:968px)  {
        padding-left: 10px;
        font-size: 11px;
        width: 70%;
      }
    }
  }
  .mail {
    margin-top: 20px;
    display: flex;

    img {
      height: 30px;

      @include mobile {
        height: 25px;
      }
    }

    p {
      padding-left: 20px;
      width: 30%;
      color: $secondary-color;
      font-size: 12px;

      @include mobile {
        padding-left: 10px;
        font-size: 11px;
      }
    }
  }
  .phone {
    margin-top: 20px;
    display: flex;

    img {
      height: 30px;

      @include mobile {
        height: 25px;
      }
    }

    p {
      padding-left: 20px;
      width: 30%;
      color: $secondary-color;
      font-size: 12px;

      @include mobile {
        padding-left: 10px;
        font-size: 11px;
        width: 50%;
      }
    }
  }

  #contact_form {
    ::placeholder {
      font-size: 12px;
    }
    .requirements {
      h4 {
        
        color: $secondary-color;
        font-weight: 300;
        font-size: 15px;
     &:not(:first-of-type){
          margin-top: 10px;
        }
        
      }

      input {
        width: 100%;
        border: 1px solid $primary-color;
        padding: 5px;
        border-radius: 8px;
        text-indent: 10px;
     
      }
    }

    .message {
      margin-top: 10px;
      textarea {
        width: 100%;
        padding-top: 10px;
        height: 100px;
        border: 1px solid $primary-color;
        border-radius: 10px;
        text-indent: 10px;
      }
    }

    .submitbutton {
      padding-top: 20px;

      button {
        height: 40px;

        border-radius: 8px;
        background-color: $third-color;
        color: $secondary-color;
        border: 1px solid $third-color;
      }
    }
  }
}
