@import "../../Global.scss";


.service-wrapper {



    // @include mobile {
    //   padding-top: 50px;
    // }

 

    .service-card {
        background-color: #1f2436;
        border-radius: 50px;
        padding: 20px;
   

        // @include mobile {
        //   margin-bottom: 20px;
        // }

        a {
          margin-top: 20px;
          text-decoration: none;
          display: block;

          img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            height: 150px;
            width: 140px;
          }

          h2 {
            font-weight: 500;
            margin-top: 20px;
          text-align: center;
            color: $secondary-color;
          }

          &:hover {
            h2 {
              color: $third-color;
            }
          }
        }
      }
  }
