@import "../../Global.scss";

.team {
  width: 100%;

  @include mobile {
    overflow-x: hidden;
  }

  .heading {
    position: relative;
    // margin-top: 0;

    img {
      width: 100%;

    
    }

    h3 {
      color: $third-color;
      font-size: 50px;
      font-weight: 500;
      padding-bottom: 200px;
      position: absolute;
      margin-top: -200px;

      @media screen and (max-width: 968px) {
        position: static;
        margin-top: 0px;
        padding-bottom: 0px;
      }
    }
  }

  .content {
    p {
      color: $secondary-color;
      word-spacing: 5px;
    }
  }

  .teammembers {
    h1 {
      color: $third-color;
      font-weight: 600;
      font-size: 40px;
      margin: 40px 0px;
    }
  }

  .footer {
    margin-top: 300px;
  }
}
