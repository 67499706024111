@import '../../Global.scss';

.foodproject{
    width: 100%;
    @include mobile{
        width: 590px;
        overflow-x: hidden;
    }

    .section2{
        height: 600px;
        background-color: #1F2436;
        margin-top: 130px;

        @include mobile{
            width: 100%;
        }

        h1{
            padding-top: 20px;
            padding-left: 120px;
            font-weight: 500;
            color: $third-color;
            font-size: 40px;

            @include mobile{
                padding-left: 30px;
                font-size: 35px;
            }
        }

        img{
            margin-left: 600px;
            margin-top: 20px;
            width: 210px;
            height: 450px;
            border: 2px solid $third-color;
            
            @include mobile{
                margin-top: 40px;
                margin-left: 170px;
            }
        }
    }

    .introduction{
        margin-top: 40px;
        margin-left: 120px;

        @include mobile{
            margin-left: 30px;
        }
        
        h2{
            color: $secondary-color;
            font-size: 25px;
            font-weight: 500;
        }

        p{
            color: $secondary-color;
            margin-top: 10px;
            width: 85%;
            text-align: justify;

            @include mobile{
                width: 95%;
            }
        }
    }

    .challenges{
        margin-top: 40px;
        margin-left: 120px;

        @include mobile{
            margin-left: 30px;
        }
        
        h2{
            color: $secondary-color;
            font-size: 25px;
            font-weight: 500;
        }

        p{
            color: $secondary-color;
            margin-top: 10px;
            width: 85%;
            text-align: justify;

            @include mobile{
                width: 95%;
            }
        }
    }

    .solution{
        margin-top: 50px;
        margin-left: 120px;

        @include mobile{
            margin-left: 30px;
        }
        
        h2{
            color: $secondary-color;
            font-size: 25px;
            font-weight: 500;
        }

        p{
            color: $secondary-color;
            margin-top: 10px;
            width: 85%;
            text-align: justify;

            @include mobile{
                width: 95%;
            }
        }
    }

    .tech{
        margin-top: 50px;
        margin-left: 120px;

        @include mobile{
            margin-left: 30px;
        }
        
        h2{
            color: $secondary-color;
            font-size: 25px;
            font-weight: 500;
        }

       li{
           margin-left: -20px;
           margin-top: 10px;
           color: $secondary-color;
           font-size: 18px;
       }
    }

    .screenshots2{
        margin-top: 50px;
        margin-left: 120px;
        
        @include mobile{
            margin-left: 30px;
        }
        
        h2{
            color: $secondary-color;
            font-size: 25px;
            font-weight: 500;
        }

        .corousal2{
            padding-top: 20px;
            display: flex;
            margin-top: 50px;
            padding-left: 200px;
            padding-bottom: 20px;

            @include mobile{
                margin-left: -180px;
                background-color: #1F2436;
            }

            .img1{

                img{
                    padding-left: 80px;
                    width: 250px;
                    height: 500px;

                    @include mobile{
                        padding-left: 0px;
                        width: 190px;
                        height: 320px;
                    }
                }
                
                p{
                    margin-left: 80px;
                    color: $third-color;

                    @include mobile{
                        margin-left: 0px;
                    }
                }
            }

            .img2{

                img{
                    padding-left: 200px;
                    width: 250px;
                    height: 500px;

                    @include mobile{
                        padding-left: 110px;
                        width: 190px;
                        height: 320px;
                    }
                }

                p{
                    margin-left: 200px;
                    color: $third-color;

                    @include mobile{
                        margin-left: 110px;
                    }
                }
            }
        }
    }
}