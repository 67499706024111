.blog-wrapper {
  .left {
    h5 {
      letter-spacing: 6px;
      padding-top: 40px;
      font-weight: 400;
      color: #ffffff;
    }
    h3 {
      color: #ffffff;
      font-size: 32px;
    }

    p {
      padding-top: 20px;
      font-size: 12px;
      padding-bottom: 30px;
      color: #ffffff;
    }

    a {
      font-size: 14px;
      text-decoration: none;
      color: #51e8ef;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}
