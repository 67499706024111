@import './Global.scss';

.app{
    height: 100vh;
    background-color: $primary-color;
    scroll-behavior: smooth;
    scrollbar-width: none;

    &::-webkit-scrollbar{
        display: none;
    }
}