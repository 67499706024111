@import "../../Global.scss";

.working-wrapper {
  h3 {
    font-size: 25px;
    font-weight: 500;
    color: $third-color;
  }

  p {
    width: 85%;
    color: $secondary-color;
    // margin-top: 40px;
    word-spacing: 3px;
    text-align: justify;
  }

  .timeline {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    @include mobile {
      margin-top: 50px;
    }

    img {
      height: 700px;
      object-fit: contain;
      @media screen  and (max-width:1012px){
        width: 100%;
        height: auto;   
      }
      
    }
  }
}
