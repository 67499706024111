@import '../../Global.scss';

.servicespage{
    width: 100%;

    .heading{
        position: relative;
        margin-top: -200px;

        @include mobile{
            margin-top: 0px;
        }

        img{
            width: 100%;

            @include mobile{
                width: 590px;
            }
        }

        .wrapper{
            display: flex;
            width: 86%;
            justify-content: space-between;
            position: absolute;
            margin-top: -80px;
            margin-left: 120px;

            @include mobile{
                flex-direction: column;
                margin-top: 20px;
                margin-left: 50px;
            }

            h3{
                color: $third-color;
                font-size: 40px;
                font-weight: 400;

                @include mobile{
                    font-size: 30px;
                }
            }

            .backbutton{

                @include mobile{
                    display: none;
                }

                a{
                    margin-top: 20px;
                    display: flex;
                    text-decoration: none;

                    img{
                        height: 20px;
                        padding-right: 10px;
                    }

                    p{
                        color: $secondary-color;
                    }
                }
            }
        }
    }

    .table{
        margin-top: 40px;

        img{
            width: 1000px;
            margin-left:220px;

            @include mobile{
                margin-top: 80px;
                margin-left: 0px;
                width: 590px;
                padding-bottom: 10px;
            }
        }
    }
}