@import "../../Global.scss";

.team-card-wrapper {
  max-width: 500px;
  width: 100%;
  max-height: 550px;
  margin: 0px auto;
  position: relative;
  margin-bottom: 300px;
  @media screen and (max-width:598px) {
    margin-bottom: 400px;

}

  img {
    width: 100%;
    object-fit: contain;
  }

  .box {
    position: absolute;
    right: -10%;
    bottom: -50%;
    z-index: 1;
    padding: 10px;
    background-color: #1f2436;
    width: 300px;
    height: 300px;
    border: 2px solid $third-color;
    box-shadow: 20px 20px 20px 0px black;
    @media screen and (max-width:598px) {
        right: 0%;
top: 100%;
width: 100%;
height: fit-content;

    }

    h3 {
      color: $secondary-color;
      font-size: 30px;
      font-weight: 600;
    }

    h5 {
      color: $third-color;
      font-size: 15px;
      font-weight: 400;
      font-style: italic;
    }

    p {
      margin-top: 10px;
      color: white;
      font-size: 13px;
      text-align: justify;
    }
  }
}
