@import "../../Global.scss";

.header {
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0)
  );

  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;

  @media screen and (max-width: 1012px) {
    background-image: unset;
    background-color: $primary-color;
    height: auto;
  }

  .header-wrapper {
    display: flex;
    padding: 20px 0px;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 1012px) {
      flex-direction: column;
    }
   

    .left {
      width: 20%;
      @media screen and (max-width: 1012px) {
        width: 50%;
        display: flex;
        justify-content: center;
      }
      @media screen and (max-width: 568px) {
        width: 90%;
        justify-content: flex-start;
      }
      img {
        height: 70px;
        object-fit: contain;
      }
    }

    .right {
      margin-left: auto;
      @media screen and (max-width: 1012px) {
        width: 100%;
        margin-left: unset;
      }

      ul {
        display: flex;
        list-style: none;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 568px) {
          flex-wrap: wrap;
        }

        li {
          &:not(:last-of-type) {
            margin-right: 50px;
          }
          @media screen and (max-width: 568px) {
            &:not(:last-of-type) {
              display: none;
            }
          }

          a {
            display: block;
            text-decoration: none;
            color: $secondary-color;
            font-weight: 200;
            font-size: 15px;

            @include mobile {
              font-size: 13px;
            }

            &:hover {
              font-weight: 200;
              color: $third-color;
            }
          }
        }
        svg {
          display: none;
          position: fixed;
          right: -5%;
          top: 30%;
          @media screen and (max-width: 1012px) {
            top: 20%;
            right: 0;
          }
          @media screen and (max-width: 568px) {
            display: block;
            top: 32%;
            right: 5%;
          }
        }
      }
    }
  }
}
.sidebar-wrapper {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 0;
  .sidebar {
    width: 50%;
    height: 100%;
    padding-top: 10%;
    padding-top: 100px;
    background-color: $primary-color;
    svg {
      position: absolute;
      left: 42%;
      top: 5%;
    }
    ul {
      list-style: none;
      li {
        display: block;
        margin-bottom: 20px;
        a {
          display: block;
          text-decoration: none;
          color: $secondary-color;
          font-weight: 200;
          font-size: 15px;

          @include mobile {
            font-size: 13px;
          }

          &:hover {
            font-weight: 200;
            color: $third-color;
          }
        }
      }
    }
  }
}
