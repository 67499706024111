@import "../../Global.scss";

.community {
  .heading {
    position: relative;
    margin-top: -220px;

    @include mobile {
      width: 590px;
      margin-top: -50px;
    }

    img {
      width: 100%;
    }

    h3 {
      position: absolute;
      margin-top: -180px;
      color: $third-color;
      font-size: 50px;
      font-weight: 500;

      @include mobile {
        margin-top: 20px;
        position: static;
        font-size: 40px;
      }
    }
  }
}
