.service {
  width: 76%;
  padding-top: 120px;
  padding-bottom: 1rem;
  margin: 0 auto;
  & > h1 {
    color: white;
  }
  & > h3 {
    color: white;
    margin-top: 4rem;
  }
  & > h4 {
    color: white;
    width: 95%;
    margin: 0 0 0 auto;
  }
  & > .sub-para {
    font-size: 15px;
    width: 90%;
    margin-left: auto;
  }
  & > p {
    font-size: 15px;
    margin: 2rem auto;
    letter-spacing: 1px;
    line-height: 28px;
    color: white;
  }
}
