@import "../../Global.scss";

.greetings {
  background-color: $primary-color;
  display: flex;
  align-items: center;
  height: 100vh;
  position: relative;

  .greeting-left {

    overflow: hidden;
    .float {
      height: 100%;
      width: 100%;
      animation: float 3s infinite;
      @media screen and (max-width: 968px) {
        animation: unset;
        // height: unset;
      }
      @media screen and (max-width: 568px) {
        visibility: hidden;
      }
    }

    @keyframes float {
      0% {
        transform: translate(0, 0px);
      }
      50% {
        transform: translate(4px, 10px);
      }
      100% {
        transform: translate(-0, 0px);
      }
    }
    .content {
      position: absolute;
      overflow: hidden;
      top: 30%;
      left: 20%;
      img {
        margin-top: 40px;
        height: 65px;
        width: 300px;
      }
      h4 {
        color: $third-color;
        font-size: 60px;
        font-weight: 500;
      }

      h3 {
        font-weight: 400;
        color: $secondary-color;
        font-size: 40px;
      }

      p {
        margin-top: 40px;
        font-size: 15px;
        font-weight: 200;
        color: $secondary-color;
      }
      @media screen and (max-width: 1012px) {
        left: 5%;
        right: 5%;
        top:30%;
      }
    }
    .arrow {
      img {
        fill: #949494;
        height: 25px;
        display: block;
        transform: translateY(-20px);
        animation: floating 5s ease-in-out infinite;
      }

      @keyframes floating {
        0% {
          transform: translateY(-20px);
        }
        50% {
          transform: translateY(0px);
        }
        100% {
          transform: translateY(-20px);
        }
      }

      @include mobile {
        display: none;
      }
    }
  }
  .greeting-right {
    overflow: hidden;
    .company-logo {
      img {
       height: 100%;
        display: block;
       margin-left: auto;
      }
    }
    @media screen and (max-width: 1012px) {
      display: none;
    }
  }
}
