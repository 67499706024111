@import '../../Global.scss';

.portfolio{
    width: 100%;

    @include mobile{
        width: 590px;
        overflow-x: hidden;
    }

    .heading{
        position: relative;
        margin-top: -220px;

        @include mobile{
            width: 590px;
            margin-top: -40px;
        }

        img{
            width: 100%;
        }

        h3{
            margin-top: -100px;
            margin-left: 120px;
            color: $third-color;
            font-size: 50px;
            font-weight: 500;

            @include mobile{
                margin-top: 20px;
                margin-left: 30px;
                font-size: 40px;
            }
        }
    }

    .wrapper{
        display: flex;
        padding-top: 60px;

        @include mobile{
            flex-direction: column;
            padding-top: 30px;
        }

        .left{
            flex:0.5;

            h3{
                margin-left: 120px;
                font-size: 30px;
                color: $secondary-color;
                width: 500px;

                @include mobile{
                    margin-left: 30px;
                    font-size: 25px;
                }
            }

            p{
                margin-left: 120px;
                text-align: justify;
                margin-top: 50px;
                font-size: 18px;
                width: 95%;
                font-weight: 350;
                color: $secondary-color;

                @include mobile{
                    margin-left: 30px;
                    margin-top: 30px;
                    width: 90%;
                }
            }
        }

        .right{
            flex:0.5;

            @include mobile{
                margin-top: 60px;
            }

            .counts{
                display: flex;
                flex-direction: column;
                margin-top: 0px;
                margin-left: 200px;
                overflow-x: hidden;

                @include mobile{
                    align-items: center;
                    margin-left: 0px;
                }

                h1{
                    font-size: 65px;
                    font-weight: 420;
                    color:$third-color;
                }
    
                h3{
                    font-weight: 250;
                    color: $secondary-color;
                }
            }
        }
    }

    .featuredprojects{
        margin-top: 30px;
        background-color: #1F2436;
        width: 100%;
        height: 1100px;

        @include mobile{
            width: 590px;
            height: 500px;
        }

        img{
            padding-top: 100px;
            padding-left: 150px;
            height: 900px;

            @include mobile{
                padding-top: 30px;
                padding-left: 20px;
                height: 440px;
            }
        }
    }

    .technologies{
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include mobile{
            margin-top: 50px;
            align-items: flex-start;
        }


        h3{
            color: $third-color;
            font-size: 35px;
            font-weight: 400;

            @include mobile{
                margin-left: 30px;
                font-size: 30px;
            }
        }

        img{
            margin-top: 50px;
            height: 380px;

            @include mobile{
                padding-left: 0px;
                height: 170px;
            }
        }
    }

    .projects{
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 20px;

        @include mobile{
            margin-top: 50px;
            margin-left: -60px;
        }

        h3{
            color: $third-color;
            font-size: 35px;
            font-weight: 400;

            @include mobile{
                margin-left: -190px;
            }
        }
    }

}
