@import "../../Global.scss";

.twitterproject {
  width: 100%;

  @include mobile {
    width: 590px;
    overflow-x: hidden;
  }

  .section1 {
    height: 600px;
    background-color: #1f2436;
    margin-top: 130px;

    @include mobile {
      height: 500px;
    }

    h1 {
      padding-top: 20px;
      padding-left: 120px;
      font-weight: 500;
      color: $third-color;
      font-size: 40px;

      @include mobile {
        padding-left: 30px;
      }
    }

    img {
      margin-left: 450px;
      margin-top: 80px;
      width: 650px;
      height: 350px;
      border: 2px solid $third-color;

      @include mobile {
        margin-left: 30px;
        height: 300px;
        width: 530px;
      }
    }
  }

  .introduction {
    margin-top: 40px;
    margin-left: 120px;

    @include mobile {
      margin-left: 30px;
    }

    h2 {
      color: $secondary-color;
      font-size: 25px;
      font-weight: 500;
    }

    p {
      color: $secondary-color;
      margin-top: 10px;
      width: 85%;
      text-align: justify;

      @include mobile {
        width: 95%;
      }
    }
  }

  .challenges {
    margin-top: 40px;
    margin-left: 120px;

    @include mobile {
      margin-left: 30px;
    }

    h2 {
      color: $secondary-color;
      font-size: 25px;
      font-weight: 500;
    }

    p {
      color: $secondary-color;
      margin-top: 10px;
      width: 85%;
      text-align: justify;

      @include mobile {
        width: 95%;
      }
    }
  }

  .solution {
    margin-top: 50px;
    margin-left: 120px;

    @include mobile {
      margin-left: 30px;
    }

    h2 {
      color: $secondary-color;
      font-size: 25px;
      font-weight: 500;
    }

    p {
      color: $secondary-color;
      margin-top: 10px;
      width: 85%;
      text-align: justify;

      @include mobile {
        width: 95%;
      }
    }
  }

  .tech {
    margin-top: 50px;
    margin-left: 120px;

    @include mobile {
      margin-left: 30px;
    }

    h2 {
      color: $secondary-color;
      font-size: 25px;
      font-weight: 500;
    }

    li {
      margin-left: -20px;
      margin-top: 10px;
      color: $secondary-color;
      font-size: 18px;
    }
  }

  .screenshots_twitter {
    margin-top: 50px;
    margin-left: 120px;

    @include mobile {
      margin-left: 30px;
    }

    h2 {
      color: $secondary-color;
      font-size: 25px;
      font-weight: 500;
    }

    .corousal {
      display: flex;
      margin-top: 50px;
      margin-left: -250px;

      @include mobile {
        background-color: #1f2436;
        flex-direction: column;
        margin-left: -50px;
      }

      .img1 {
        img {
          padding-left: 250px;
          width: 380px;
          height: 220px;

          @include mobile {
            padding-top: 20px;
            padding-left: 120px;
          }
        }

        p {
          margin-left: 250px;
          color: $third-color;

          @include mobile {
            padding-bottom: 30px;
          }
        }
      }

      .img2 {
        img {
          padding-left: 100px;
          width: 380px;
          height: 220px;

          @include mobile {
            padding-left: 120px;
          }
        }

        p {
          margin-left: 100px;
          color: $third-color;

          @include mobile {
            margin-left: 120px;
            margin-bottom: 30px;
          }
        }
      }

      .img3 {
        img {
          padding-left: 100px;
          width: 380px;
          height: 220px;

          @include mobile {
            padding-left: 120px;
          }
        }

        p {
          margin-left: 100px;
          color: $third-color;

          @include mobile {
            margin-left: 120px;
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}
