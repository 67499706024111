@import '../../Global.scss';

.projectcorousal_cloud{
    padding-top: 40px;
    padding-bottom: 5px;
    width: 100%;
    
    div{
        

        img{
            width: 400px;
            height: 230px;
            padding-bottom: 20px;
            padding-left: 80px;

            @include mobile{
                width: 280px;
                height: 160px;
                padding-left: 15px;
            }
        }

        p{
            color: $third-color;
            margin-top: -20px;
            padding-left: 80px;
            @include mobile{
                margin-left: 0px;
                padding-left: 15px;
            }
            
        }
    }
}