@import "../../Global.scss";

.blog {
  width: 100%;

  //   @include mobile {
  //     width: 590px;
  //     overflow-x: hidden;
  //   }

  .heading {
    position: relative;
    height: 100vh;
    img {
      width: 100%;
      height: 100%;
    }

    .header-content {
      position: absolute;
      top: 50%;
      width: 100%;
      h3 {
        padding-bottom: 50px;
        color: $third-color;
        font-size: 50px;
        font-weight: 500;

        //     @include mobile {
        //       position: static;
        //       margin-top: 20px;
        //       margin-left: 30px;
        //       padding-bottom: 0px;
        //       font-size: 40px;
        //     }
        //   }
      }

      p {
        color: $secondary-color;
        font-style: italic;

        //   @include mobile {
        //     position: static;
        //     margin-left: 30px;
        //     margin-top: 20px;
        //   }
      }
    }
  }
  .new-blog-title {
    font-size: 35px;
    font-weight: 500;
    color: $third-color;


    //       @include mobile {
    //         font-size: 30px;
    //       }
  }
}
