@import "../../Global.scss";

.testimonial-wrapper {
  background-color: #1f2436;
  border-radius: 50px;

  .card1 {
    margin-top: 40px;
    flex: 3;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      height: 100px;
    }

    h4 {
      padding-top: 20px;
      color: $third-color;
      font-weight: 500;
    }

    h5 {
      color: $secondary-color;
      font-weight: 300;
    }

    p {
      color: $secondary-color;
      padding-top: 30px;
      padding-right: 50px;
      padding-left: 50px;
      text-align: center;
      font-size: 12px;
      font-style: oblique;
    }
  }

  .card2 {
    margin-top: 40px;
    flex: 3;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      height: 100px;
    }

    h4 {
      padding-top: 20px;
      color: $third-color;
      font-weight: 500;
    }

    h5 {
      color: $secondary-color;
      font-weight: 300;
    }

    p {
      color: $secondary-color;
      padding-top: 50px;
      padding-right: 50px;
      padding-left: 50px;
      text-align: center;
      font-size: 12px;
      font-style: oblique;
    }
  }

  .card3 {
    margin-top: 40px;
    flex: 3;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      height: 100px;
    }

    h4 {
      padding-top: 20px;
      color: $third-color;
      font-weight: 500;
    }

    h5 {
      color: $secondary-color;
      font-weight: 300;
    }

    p {
      color: $secondary-color;
      padding-top: 50px;
      padding-right: 50px;
      padding-left: 50px;
      text-align: center;
      font-size: 12px;
      font-style: oblique;
    }
  }

  .card4 {
    margin-top: 40px;
    flex: 3;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      height: 110px;
    }

    h4 {
      padding-top: 20px;
      color: $third-color;
      font-weight: 500;
    }

    h5 {
      color: $secondary-color;
      font-weight: 300;
    }

    p {
      color: $secondary-color;
      padding-top: 50px;
      padding-right: 50px;
      padding-left: 50px;
      text-align: center;
      font-size: 12px;
      font-style: oblique;
    }
  }

  .more {
    height: 50px;
    margin-top: 15px;
    width: 100%;
    display: flex;
    background-color: #4d5470;
    border-radius: 50px;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    a {
      text-decoration: none;

      p {
        font-size: 13px;
        color: $secondary-color;
      }

      &:hover {
        p {
          color: $third-color;
        }
      }
    }
  }
}
