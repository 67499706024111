@import '../../Global.scss';

.projectcorousal_machina{
    padding-top: 40px;
    padding-bottom: 5px;
    width: 100%;
    
    div{
        

        img{
            width: 360px;
            height: 210px;
            padding-bottom: 20px;
            padding-left: 70px;
            @include mobile{
                width: 280px;
                height: 150px;
                padding-left: 20px;
            }
        }

        p{
            color: $third-color;
            margin-top: -20px;
            padding-left: 70px;
            @include mobile{
                margin-left: 0px;
                padding-left: 20px;
            }
        }
    }
}